:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: 1rem;
  --gap: 1rem;
  --duration: 300s;
  --scroll-start: 0;
  --scroll-end: -100%;
}
